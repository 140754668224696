import React from 'react';
import styled from 'styled-components';
import { BlogImage, Title } from 'components/blog';
import Container from 'components/container';
import PublicLayout from 'components/layouts/Public';
import { BlogPostContentType } from 'constants/blog';
import { NOT_FOUND_IMAGE } from 'constants/images';

// TODO: move this to just one component for all pages
const FullWidthDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: left;
`;

const NotFound = () => {
  return (
    <PublicLayout title="Page Not Found">
      <Container centerContent py="20px" pylg="20px">
        <FullWidthDiv>
          <Title margin="15px 0 15px 40px">Page not found</Title>
        </FullWidthDiv>
        <BlogImage alt="Blog Image" type={BlogPostContentType.NORMAL_IMAGE} src={NOT_FOUND_IMAGE} />
      </Container>
    </PublicLayout>
  );
};

export default NotFound;
